import React, {  } from "react";
//import './App.css';
import "./css/Dashboard.css";
import "antd/dist/reset.css";

import { Navigate, Route, Routes } from "react-router-dom";
import { Register } from "./Pages/FFood/Register";

const App: React.FC = () => {

  return (
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Navigate to="/register" />} />
      </Routes>
  );
};

export default App;
